.container {
  display: flex;
  padding: 1px 0 18px 0;
}

.divider {
  align-self: center;
  font-size: 14px;
  font-weight: 300;
  margin: 0 9px;
}

.error {
  font-size: 12px;
  font-weight: 300;
  color: #cc0000;
  margin-bottom: 15px;
}

.arrow {
  width: 16px;
  height: 100%;
}

.inputRangeButton {
  padding: 0 9px !important;
  margin-left: 9px;
  border-width: 1px !important;
  flex-shrink: 0;
}