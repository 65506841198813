.container {
  margin: 9px 0 18px;
  font-size: 14px;
  font-weight: 300;
}

.clear:before {
  content: "|";
  margin: 0 9px;
}
