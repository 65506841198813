.wrapper {
  font-size: 12px;
  font-weight: 300;
  padding: 9px 0;
  list-style: none;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.wrapper::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .wrapper {
    padding: 0;
    margin: 9px 0;
  }
}

.lastItem {
  color: #222;
}

.lastItem,
.item {
  display: inline-block;
}
.lastItem + .lastItem:before,
.lastItem + .item:before,
.item + .lastItem:before,
.item + .item:before {
  display: inline-block;
  cursor: initial;
  content: "/";
  padding: 0 9px;
}
@media (max-width: 768px) {
  .lastItem + .lastItem:before,
  .lastItem + .item:before,
  .item + .lastItem:before,
  .item + .item:before {
    padding: 0 6px;
  }
}