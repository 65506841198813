.container {
  margin: 27px 0;
}

.result {
  cursor: pointer;
  color: #888;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.listItem:not(:last-child) {
  padding-bottom: 27px;
}

.searchResultIcon {
  vertical-align: middle;
  color: #bbb;
  width: 25px;
  height: 25px;
  margin-right: 18px;
}
