.wrapper {
  margin-bottom: 9px;
  display: block;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  flex-basis: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
