button.button {
  display: block;
  padding-bottom: 9px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 300;
}

button.buttonSpacingTop {
  padding-top: 9px;
}