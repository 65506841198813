.article {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media (width <= 1024px) {
  .article {
    margin: 0 0 18px;
  }
}

@media (width >= 1025px) {
  .article.stacked {
    display: block;
  }

  .article.stacked .articleElement:first-child {
    flex: unset;
    margin: 0 0 27px;
  }

  .article.stacked .articleElement:last-child {
    flex: unset;
    margin: 0;
  }

  @supports (-webkit-line-clamp: 2) {
    .article.stacked .articleElement .header {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }

  @supports (-webkit-line-clamp: 3) {
    .article.stacked .articleElement .body {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }
}

.article.hidden {
  display: none;
}

.articleElement {
  flex: 1;
  position: relative;
}

.articleElement:first-child {
  min-height: 141px;
  margin-right: 18px;
}

@media (width <= 1024px) {
  .articleElement:first-child {
    min-height: auto;
    max-height: 110px;
    margin-right: 0;
  }
}

.articleElement:last-child {
  margin-left: 18px;
}

.featuredImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.header {
  letter-spacing: -.5px;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0 0 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@supports (-webkit-line-clamp: 3) {
  .header {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media (width <= 1024px) {
  .header {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.body {
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

@supports (-webkit-line-clamp: 4) {
  .body {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media (width <= 1024px) {
  .body {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }

  @supports (-webkit-line-clamp: 2) {
    .body {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }
}
