.tileWrapper {
  flex-direction: column;
  align-items: center;
  min-width: 0;
  display: flex;
  position: relative;
}

@media (width >= 1025px) {
  .tileWrapper {
    margin-bottom: 18px;
  }
}

.tileWidth {
  flex-basis: 50%;
  max-width: 50%;
}

@media (width >= 1025px) {
  .tileWidth {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}

.completeInfo .favoritesWrapper {
  z-index: 10;
  margin-top: 0;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
}

.reducedIcon {
  transform: scale(.8);
}

.heartIconWrapperSpacing {
  margin: 0 auto;
}

.favoritesWrapper {
  z-index: 10;
  margin-top: 0;
  padding: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width <= 568px) {
  .favoritesWrapper {
    padding: 9px;
  }
}

.favoritesItemProvider {
  width: 100%;
}
