.container {
  flex-wrap: wrap;
  padding: 0 0 27px;
  display: flex;
}

.colorSelect {
  flex-basis: 14.2857%;
  padding-bottom: 14%;
  position: relative;
}

.swatch {
  appearance: none;
  pointer-events: auto;
  outline-offset: 1px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 100%;
  width: 27px;
  height: 27px;
  margin: 3px;
  position: absolute;
  inset: 0;
}

.swatch.selected {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #888;
}

.disabled {
  pointer-events: none;
}
