.container {
  color: #888;
  overflow: auto;
}

.inputWrapper {
  background: #fbfbfb;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 18px;
}

.button {
  display: block;
  padding-bottom: 18px;
}

.searchInput {
  margin: 18px 0;
  background: #fff;
  color: #222;
  font-size: 14px;
  font-weight: 300;
}