.container {
  display: flex;
}
.container:not(:first-of-type) {
  padding-top: 9px;
}
.container:last-of-type {
  padding-bottom: 18px;
}

.inputWrapper {
  width: 100%;
}

.anchor {
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.label {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  font-weight: 300;
}
.label:hover {
  color: #444;
}
@media (hover: none) {
  .label:hover {
    color: #000;
  }
}
.label:hover {
  text-decoration: underline;
}

.disabled {
  pointer-events: none;
}

.count {
  color: #666;
  margin-top: 3px;
  margin-left: 9px;
  font-size: 12px;
  font-weight: 300;
}