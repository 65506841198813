@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.wrapper {
  height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-top: 9px;
  padding: 0 18px 45px 18px;
  flex-basis: 50%;
  max-width: 50%;
}
@media (min-width: 1025px) {
  .wrapper {
    transition: box-shadow 0.15s ease;
    margin-bottom: 18px;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .wrapper:hover {
    box-shadow: 1px 2px 6px 0 rgba(34, 34, 34, 0.3);
  }
  .wrapper:hover .tileHovered {
    display: block;
  }
}
.wrapper.item5InRow {
  max-width: 20%;
  flex-basis: 20%;
}
.wrapper.item2InRow {
  max-width: 50%;
  flex-basis: 50%;
}
@media (max-width: 768px) {
  .wrapper {
    padding: 45px 9px 18px;
    margin-top: 0;
  }
  .wrapper.photosOnly {
    padding-top: 0;
  }
}

.image,
.title,
.subtitle,
.price {
  animation-name: shimmerLoadingBackground;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50%, #f3f3f3 51%, #ddd 100%);
  background-size: 400% 400%;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.tile {
  height: 100%;
  width: 100%;
  padding: 18px;
}

.image {
  height: 240px;
  margin-bottom: 18px;
}

.title,
.subtitle {
  height: 15px;
  margin-bottom: 18px;
}

.price {
  height: 15px;
  width: 45px;
}