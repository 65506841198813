/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.container {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  background-color: #f3f3f3;
  padding: 36px 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container.useWhiteBg {
  background-color: #fff;
  padding: 0 54px 36px;
}
.container.useWhiteBg .viewMoreButton {
  background-color: #fff;
}
@media (max-width: 568px) {
  .container.useWhiteBg {
    padding: 18px;
  }
}
@media (max-width: 568px) {
  .container {
    padding: 18px;
  }
}

.sectionHeader {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 54px;
}
@media (max-width: 568px) {
  .sectionHeader {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin: 0 0 27px;
  }
}

@media (min-width: 1025px) {
  .articlesWrapper {
    margin: 0 36px 18px;
    display: grid;
    grid-gap: 72px;
    max-width: 1240px;
  }
  .articlesWrapper.col1 {
    max-width: 700px;
    grid-template-columns: 1fr;
  }
  .articlesWrapper.col2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .articlesWrapper.col3 {
    max-width: 915px;
    grid-template-columns: repeat(3, 1fr);
  }
  .articlesWrapper.col4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.viewMoreButton {
  display: block;
  margin: 27px auto;
  width: 200px;
  background-color: #f3f3f3;
}
@media (max-width: 568px) {
  .viewMoreButton {
    width: 100%;
    margin: 0;
  }
}