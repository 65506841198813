button.container {
  text-align: left;
  justify-content: space-between;
  width: 100%;
  padding-top: 9px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

button.container:last-of-type {
  padding-bottom: 18px;
}

button.container a {
  text-decoration: none;
}

button.container:hover {
  color: initial;
  cursor: pointer;
}

button.container:first-of-type {
  padding-top: 0;
}

.disabled {
  pointer-events: none;
  color: #888;
}

.indent {
  width: calc(100% - 18px);
  margin-left: 18px;
  padding-right: 18px;
}

.text:hover {
  color: #222;
  text-decoration: underline;
}

.selected .text {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.selected .text:hover {
  color: initial;
}

.count {
  color: #666;
  margin-top: 3px;
  padding-left: 9px;
  font-size: 12px;
  font-weight: 300;
}
