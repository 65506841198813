.noScroll {
  overflow: hidden;
  position: fixed !important;
}

.body {
  z-index: 10020;
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  overflow: scroll;
  right: 0;
  top: 0;
}

.aboveOverlay {
  z-index: 10030;
}

.menu {
  composes: body;
  border-top: 5px solid #c2a661;
}

.includePadding {
  padding: 5px 2.5%;
}

.transparent {
  background-color: rgba(0, 0, 0, 0.7);
}