.noScroll {
  overflow: hidden;
  position: fixed !important;
}

.body {
  z-index: 10020;
  background: #fff;
  position: fixed;
  inset: 0;
  overflow: scroll;
}

.aboveOverlay {
  z-index: 10030;
}

.menu {
  composes: body;
  border-top: 5px solid #c2a661;
}

.includePadding {
  padding: 5px 2.5%;
}

.transparent {
  background-color: #000000b3;
}
