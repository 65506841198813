.unitSelectorContainer {
  margin-bottom: 27px;
  display: flex;
}

.unitSelector {
  margin-right: 9px;
}

.unitSelectorLabel {
  margin-left: 9px;
  margin-right: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.expandFilters {
  padding-top: 9px;
  padding-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.ringSizeFilterContainer {
  grid-template-rows: 30px;
  grid-template-columns: repeat(4, 48px);
  grid-auto-rows: 30px;
  gap: 9px;
  display: grid;
}

@media (width <= 768px) {
  .ringSizeFilterContainer {
    grid-template-columns: repeat(5, 48px);
  }
}

.spacer {
  height: 50px;
}

.ringSizeFilterTile {
  cursor: pointer;
  border: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  width: 48px;
  display: flex;
}

.ringSizeFilterTile.selected {
  border: 2px solid #222;
}
