.container:first-of-type {
  padding-top: 18px;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
