.button {
  --noop-rule: 0;
}

.heartLink {
  height: 33px;
  width: 33px;
  -webkit-tap-highlight-color: transparent;
}

.heartIcon {
  transition: fill 0.2s ease;
}