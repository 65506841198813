.button {
  --noop-rule: 0;
}

.heartLink {
  -webkit-tap-highlight-color: transparent;
  width: 33px;
  height: 33px;
}

.heartIcon {
  transition: fill .2s;
}
