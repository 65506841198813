.article {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1024px) {
  .article {
    margin: 0 0 18px;
  }
}
@media (min-width: 1025px) {
  .article.stacked {
    display: block;
  }
  .article.stacked .articleElement:first-child {
    flex: unset;
    margin: 0 0 27px;
  }
  .article.stacked .articleElement:last-child {
    flex: unset;
    margin: 0;
  }
  @supports (-webkit-line-clamp: 2) {
    .article.stacked .articleElement .header {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    .article.stacked .articleElement .header {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
  }
  @supports (-webkit-line-clamp: 3) {
    .article.stacked .articleElement .body {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
    .article.stacked .articleElement .body {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
  }
}
.article.hidden {
  display: none;
}

.articleElement {
  flex: 1;
  position: relative;
}
.articleElement:first-child {
  min-height: 141px;
  margin-right: 18px;
}
@media (max-width: 1024px) {
  .articleElement:first-child {
    min-height: auto;
    max-height: 110px;
    margin-right: 0;
  }
}
.articleElement:last-child {
  margin-left: 18px;
}

.featuredImg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 9px;
  text-overflow: ellipsis;
  word-break: break-word;
}
@supports (-webkit-line-clamp: 3) {
  .header {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  .header {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 1024px) {
  .header {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}

.body {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  text-overflow: ellipsis;
  word-break: break-word;
}
@supports (-webkit-line-clamp: 4) {
  .body {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }
  .body {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 1024px) {
  .body {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }
  @supports (-webkit-line-clamp: 2) {
    .body {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    .body {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
  }
}