.container {
  display: flex;
  align-items: center;
  padding-top: 18px;
}
.container:first-of-type {
  padding-top: 0;
}
.container:last-of-type {
  padding-bottom: 18px;
}

.checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.checkbox:global(.focus-visible) + .label {
  outline: none;
  outline: #666 solid 2px;
}
.checkbox:focus-visible + .label {
  outline: #666 solid 2px;
}

.stoneCutContainer {
  height: 25px;
  width: 25px;
  color: #bbb;
  margin-right: 18px;
}

.label {
  cursor: pointer;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  width: 100%;
}

.count {
  font-size: 12px;
  font-weight: 300;
  margin-left: 9px;
  color: #666;
}

.input {
  text-decoration: none;
  color: #222;
  width: 100%;
  min-width: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.checked {
  color: #222;
}