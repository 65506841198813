.wrapper {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 9px 0;
  font-size: 12px;
  font-weight: 300;
  list-style: none;
  overflow: auto hidden;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

@media (width <= 768px) {
  .wrapper {
    margin: 9px 0;
    padding: 0;
  }
}

.lastItem {
  color: #222;
}

.lastItem, .item {
  display: inline-block;
}

.lastItem + .lastItem:before, .lastItem + .item:before, .item + .lastItem:before, .item + .item:before {
  cursor: initial;
  content: "/";
  padding: 0 9px;
  display: inline-block;
}

@media (width <= 768px) {
  .lastItem + .lastItem:before, .lastItem + .item:before, .item + .lastItem:before, .item + .item:before {
    padding: 0 6px;
  }
}
