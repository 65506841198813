.container {
  margin: 18px 0;
  margin-top: 9px;
  font-size: 14px;
  font-weight: 300;
}

.clear:before {
  content: "|";
  margin: 0 9px;
}