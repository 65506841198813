.unitSelectorContainer {
  display: flex;
  margin-bottom: 27px;
}

.unitSelector {
  margin-right: 9px;
}

.unitSelectorLabel {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  margin-left: 9px;
  margin-right: 18px;
}

.expandFilters {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  padding-top: 9px;
  padding-bottom: 18px;
}

.ringSizeFilterContainer {
  display: grid;
  grid-template-columns: repeat(4, 48px);
  grid-template-rows: 30px;
  grid-auto-rows: 30px;
  gap: 9px 9px;
}
@media (max-width: 768px) {
  .ringSizeFilterContainer {
    grid-template-columns: repeat(5, 48px);
  }
}

.spacer {
  height: 50px;
}

.ringSizeFilterTile {
  display: flex;
  border: 1px solid #ddd;
  width: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ringSizeFilterTile.selected {
  border: 2px solid #222;
}