.container {
  background-color: #f3f3f3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 36px 0 18px;
  display: flex;
  position: relative;
  left: calc(-50vw + 50%);
}

.container.useWhiteBg {
  background-color: #fff;
  padding: 0 54px 36px;
}

.container.useWhiteBg .viewMoreButton {
  background-color: #fff;
}

@media (width <= 568px) {
  .container.useWhiteBg, .container {
    padding: 18px;
  }
}

.sectionHeader {
  letter-spacing: -.5px;
  margin: 0 0 54px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 568px) {
  .sectionHeader {
    letter-spacing: -.5px;
    margin: 0 0 27px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

@media (width >= 1025px) {
  .articlesWrapper {
    grid-gap: 72px;
    max-width: 1240px;
    margin: 0 36px 18px;
    display: grid;
  }

  .articlesWrapper.col1 {
    grid-template-columns: 1fr;
    max-width: 700px;
  }

  .articlesWrapper.col2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .articlesWrapper.col3 {
    grid-template-columns: repeat(3, 1fr);
    max-width: 915px;
  }

  .articlesWrapper.col4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.viewMoreButton {
  background-color: #f3f3f3;
  width: 200px;
  margin: 27px auto;
  display: block;
}

@media (width <= 568px) {
  .viewMoreButton {
    width: 100%;
    margin: 0;
  }
}
